import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"

import { StaticImage } from "gatsby-plugin-image"

import ProjectHeader from "../../components/project-header/project-header"
import ReadNext from "../../components/read-next/read-next"
import Index from "../../components/index"
import { RoughNotation } from "react-rough-notation"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const chapters = [
  {
    name: `Overview`,
    scrollTo: 'overview',
  },
  {
    name: 'Motivation',
    scrollTo: 'motivation',
  },
  {
    name: 'Development',
    scrollTo: 'development',
  },
  {
    name: 'Demo',
    scrollTo: 'demo',
  },
];

const theme = projects['postureAnalysis'].themeColor;

const ProjectPage = () => (
  <>
    <Seo title="Posture Analysis" />
    <ProjectHeader projectObj={projects['postureAnalysis']} />
    <section className={`fix-top fix-bottom`} id={`overview`}>
      <Index chapters={chapters} />
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Overview</h6>
        <h2 className={`add-margin-bottom`}>Objective</h2>
        <p className={`primary lh`}>We wanted to build a non-intrusive wearable device that one could simply attach to their clothing and gain accurate insights about their posture throughout the day. The device should be able to provide feedback to the user to correct his or her posture whenever identified as improper. For this, the device must be able to correctly identify and classify the spinal curvature. It should also be able to communicate with the user in some way.</p>
        <div className={`add-margin-top`}>
          <h6 className={`center`} style={{ color: theme}}>Problem</h6>
          <h4 className={`center`}>How to design a <RoughNotation type={`underline`} show color={theme}>wearable device</RoughNotation> that can monitor and subtly correct a user's posture?</h4>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`motivation`}>
      <div className={`content--md-center`}>
      <h6 style={{ color: theme}}>Motivation</h6>
      <h2 className={`add-margin-bottom`}>Why Posture Analysis?</h2>
      <p className={`primary lh add-margin-top`}>The increase in improper posture including lower back pain (LBP) and neck and shoulder pain (NSP) has been related to increased desk-based activities such as using computers. As of April 2019, 4.4 billion people were active internet users. The reason for the development of improper posture can be accounted to the fact that repeating one activity throughout the day without reprieve can lead to the body orienting and adjusting itself to perform that action more efficiently.</p>
      <h3 className={`add-margin-top`}>Applications & Advantages</h3>
      <p className={`primary lh add-margin-top`}>Posture analysis finds important applications in <RoughNotation type={`underline`} show multiline color={theme}>bio-medical studies, healthcare and workplaces</RoughNotation>. There are several advantages of maintaining a healthy posture. The proper alignment of spinal curves leads to even distribution of weight along with balance amongst different parts of the body like the spine, shoulders, knees, hips etc. This helps define a good posture which is important for daily activities involving sitting, standing, and lying down. Having proper posture keeps bones and joints in correct alignment alleviating strain on the spine and decreasing the abnormal wearing of joint surfaces that could result in degenerative arthritis and joint pain. It also reduces muscle and ligament stress along joints, minimizing the likelihood of injury and preventing muscle fatigue.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`development`}>
      <div className={`content--md-center`}>
      <h6 style={{ color: theme}}>Development</h6>
      <h2>The Implementation</h2>
      <h4 className={`margin-top`}>Hardware & Communication</h4>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/posture-analysis/Prototype.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
          <p className={`caption`}>Circuit Diagram & Prototype</p>
        </div>
      <p className={`primary lh`}>To estimate the complete posture accurately, we needed sensors to estimate the three curves of the spine — namely cervical, thoracic, and lumbar. Having accelerometers at the three locations allowed us to understand their inclination with reference to the ground, enabling us to reconstruct the spinal curve. Each accelerometer gave us data about acceleration in triaxial dimensions that was processed to identify its roll and pitch (which are essentially the rotations of the accelerometer along the x-axis and y-axis in 3D space respectively).</p>
      <h5 className={`lh`}>We used three <RoughNotation type={`underline`} show multiline color={theme}>ADXL345 accelerometers</RoughNotation> that were placed at a fixed distance of 15cm from each other.</h5>
      <p className={`primary lh add-margin-top`}>We wanted the information to be transmitted over the internet for several reasons:</p>
      <ul>
        <li>The device would not be limited by distance such as bluetooth range</li>
        <li>It would be easier for multiple devices to access the data</li>
        <li>Access of the user's real-time data could be easily shared say with the user's doctor</li>
      </ul>
      <p className={`primary lh`}>To implement the input of sensor data, processing, and transmission over wifi, we chose a wifi module + microprocessor. We used the <RoughNotation type={`underline`} show multiline color={theme}>NodeMCU ESP8266</RoughNotation> to act as a central chip that inputs data from the three sensors, processes the values to obtain its inclination in degrees, and uses wifi to transmit to the database.</p>
      <div className={`content--md-center`}>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/posture-analysis/Firebase.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
        </div>
      </div>
      <p className={`primary lh`}>We used <RoughNotation type={`underline`} show multiline color={theme}>Google's Firebase</RoughNotation> as our real-time database because of its web socket implementation. This helped us make a dynamic interface where change in data at our database by the wifi module reflected instaneously on the devices accessing the database.</p>
        <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/posture-analysis/PostureTypes.png'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
        </div>
      <h4 className={`margin-top`}>Classifying Posture</h4>
      <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/posture-analysis/NeuralNet.jpg'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
        </div>
      <p className={`primary lh`}>The device can classify if a posture is good, average, or bad based on the estimated reconstruction of posture. This is done by training a model on a manually labelled dataset of 450 data points (150 data points for each class). The dataset consists of three features that are the inclination values of three accelerometers. We trained a neural network on this dataset and found an accuracy of <RoughNotation type={`circle`} show color={theme}><b>95.54%</b></RoughNotation> on our test data.</p>
      <h4 className={`margin-top`}>Interface</h4>
      <div className={`project-image`}>
          <StaticImage
            src={'../../images/projects/posture-analysis/Interface.jpg'}
            width={1200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stages of the programming experience"
            loading={`eager`}
          />
        </div>
      <p className={`primary lh`}>Our interface consists of a graphical display with a representation of the estimated spinal curve from the three sensors. The display also shows if the posture is classified as good, average, or bad. In addition, a timer shows how long the user has been wearing the device and a visual demonstrating how often the user was in a good posture or a bad posture during that time.</p>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`demo`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Demo</h6>
        <h2 className={`add-margin-bottom`}>Project Simulator</h2>
        <p className={`primary lh`}>Since the wearable technology is not accessible to everyone for a demonstration, we made this simulation where a user can control the inclination of the sensors and view how the posture estimate changes.</p>
        <blockquote>
          <h5 className={`lh margin-top`}>Step 1. Open the simulation interface that displays the estimated posture representation</h5>
          <a href="https://posture-analysis.github.io/" target="_blank"><button className={`sm`}>Simulation View <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></button></a>
          <h5 className={`lh margin-top`}>Step 2. Open the update page  on a new device or tab to change the accelerometer rotations</h5>
          <a href="https://posture-analysis.github.io/" target="_blank"><button className={`sm`}>Updation View <span className={`icon`}><FontAwesomeIcon icon={faExternalLinkAlt} /></span></button></a>
          <h5 className={`lh margin-top`}>Step 3. Observe how the interface graph changes real-time as you update the values</h5>
        </blockquote>
        <p className={`primary lh`}>You may note while using the simulation, that changing the middle accelerometer linearly changes the values in the top and bottom accelerometers. This is because the middle accelerometer acts as a reference node to understand how the back is aligned with the ground i.e. if the user is standing, lying down, or bending. The difference between the top and middle accelerometers estimates the upper curvature of the spine and the bottom and middle accelerometers inform us about the lower curvature.</p>
      </div>
    </section>
    <ReadNext projectObj={projects['signLanguageTranslation']} />
  </>
)

export default ProjectPage